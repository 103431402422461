import React from 'react';
import meeting from '../../assets/meeting.jpg';
import crowd from '../../assets/crowd-execs.jpg';
import people from '../../assets/people.jpg'
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: crowd,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>Executive Search</p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <div>
                  <p>
                    Searching for the next Executive Talent to lead your company, partner with Gates – your one
                    stop APAC HR specialist.
                    We specialise in executive searches from middle to top management positions across a wide
                    range of industries.

                    <ul>
                      <li>Accounting  &amp; Audit</li>
                      <li>Advertising &amp; Design</li>
                      <li>Banking &amp; Finance</li>
                      <li>E-commerce</li>
                      <li>Hospitality</li>



                    </ul>


                  </p>
                </div>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: meeting,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>Local Placements</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <p>
            Permanent, Temporary and Contract, Event, Outsourcing and Payroll Outsourcing – we provide fast
            access to suitable qualified talents. With a focused approach to matching the right candidate with
            the right position, we deliver better business results for our clients.{' '}
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: people,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>Foreign Placements</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>
            Our extensive network in APAC allows our clients to tap on foreign talents to complement our local
            workforce. Need workers in Hospitality, Retail, Customer Service, Manufacturing and Construction,
            we deliver with pin point accuracy and speed.{' '}
          </p>
        </span>
      </span>
    ),
  },
};

import React from 'react';
export const Nav32DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    href: '/talent',
    children:
      'https://www.gateshr.com/static/media/gateslogo.9c1ba14f.png',

  },
  Menu: {
    className: 'header3-menu kmhq79ksa2m-editor_css',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '/about',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>About Us</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        // subItem: [
        //   {
        //     name: 'sub0',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //             'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: 'Ant Design',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '企业级 UI 设计体系',
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     name: 'sub1',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //             'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: 'Ant Design',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '企业级 UI 设计体系',
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/talent',
          children: [
            {
              children: (
                <span>
                  <p>Talent Recruitment</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '/manpower',
          children: [
            {
              children: (
                <span>
                  <p>Manpower</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      // {
      //   name: 'item3',
      //   className: 'header3-item',
      //   children: {
      //     href: '/aes',
      //     children: [
      //       {
      //         children: (
      //           <span>
      //             <span>
      //               <span>
      //                 <p>Anti-Epidermic Solutions</p>
      //               </span>
      //             </span>
      //           </span>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      // },
      {
        name: 'item4',
        className: 'header3-item',
        children: {
          href: 'hrtech',
          children: [
            {
              children: (
                <span>
                  <p>HR Technology</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item5',
        className: 'header3-item',
        children: {
          href: 'partners',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>Our Partners</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item6',
        className: 'header3-item',
        children: {
          href: 'contact',
          children: [
            {
              children: (
                <span>
                  <p>Contact Us</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
    subItem: {},
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper kmhqkiq53m-editor_css',
        },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
          children:
            'https://www.gateshr.com/static/media/gateslogo.9c1ba14f.png',
        },
        content: {
          className: 'banner1-content',
          children: (
            <span>
              <p className="sub-title">Your Partner for SUCCESS</p>
            </span>
          ),
        },
        button: { className: 'banner1-button', children: 'Click to learn more' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
            'https://www.gateshr.com/static/media/gateslogo.9c1ba14f.png',
        },
        content: {
          className: 'banner1-content',
          children: (
            <span>
              <p className="sub-title">Form your winning team</p>
            </span>
          ),
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'banner1-title',
          children:
            'https://www.gateshr.com/static/media/gateslogo.9c1ba14f.png',
        },
        content: {
          className: 'banner1-content',
          children: (
            <span>
              <p className="sub-title">Delighting your customers</p>
            </span>
          ),
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
    ],
  },
};
export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Your Success is Our Success</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <h3>
              <br />
            </h3>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>Talent</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  With our database of more than 10,000 talents, let us fulfill
                  your talent needs and provide the key to unlock your success.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Manpower</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  We provide a seamless manpower outsourcing service to meet
                  your event and temporary manpower needs.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>HR Technology</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Digitalization enables a seamless integration to your
                  operations so you can focus on your core business.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Anti-Epidermic Solutions</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Let us take care of a healthy work environment for your staff
                  by providing virus protective layer on all your touch points
                  in the office.&nbsp;
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Partnership</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  We partner with industry leading partners to bring you a
                  one-stop solution for all your HR needs.&nbsp;
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Customer Support</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Always ready to serve, our customer support can always be
                  called upon anytime.&nbsp;
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2021 <a href="#">Gates international Solutions Pte Ltd</a> All Rights
        Reserved
      </span>
    ),
  },
};
